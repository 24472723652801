import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueNumberFormat from 'vue-number-format';
import VueCryptojs from 'vue-cryptojs';
Vue.config.productionTip = false;
Vue.use(VueNumberFormat, { prefix: 'Rp ', suffix: ',-', thousand: '.' });
Vue.use(VueCryptojs);
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
