<template>
  <pembayaran></pembayaran>
</template>

<script>
import { pembayaran } from "../components/index";
export default {
  components: { pembayaran },
  name: "Pembayaran",
};
</script>

<style>
</style>