<script>
import axios from "axios";
import jsPDF from "jspdf";
import { paket, paketlayanan } from "../../services/index";
export default {
  data: () => ({
    path: "/paket",
    namaPaket: "",
    loading: false,
    download: false,
    paket: [],
    paketlayanan: [],
    choicePaket: "",
    emailRules: [
        v => !!v || 'E-mail harus di isi',
        v => /.+@.+\..+/.test(v) || 'Gunakan email valid',
      ],
    form: {
      nama: "",
      noWA: "",
      akunIG: "",
      Email: "",
      paket: "",
    },
    rules: [(v) => !!v || "required"],
  }),

  mounted() {
    this.loadPaket();
    this.loadPaketLayanan();
  },
  methods: {
    validate() {},
    loadPaketLayanan() {
      try {
        axios.get(paketlayanan).then((response) => {
          // console.log(response);
          switch (response.data.status) {
            case 200:
              const paket = [];
              response.data.results.forEach((data) => {
                paket.push({
                  idPaket: data.id,
                  nama: data.title,
                  harga: data.harga,
                });
              });
              this.paketlayanan = paket;
              console.log(this.paketlayanan);
              break;
            default:
              console.log(response.data.message);
              break;
          }
        });
      } catch (error) {
        console.log(err);
      }
    },
    loadPaket() {
      axios
        .get(paket)
        .then((result) => {
          const paket = [];
          result.data.results.forEach((data) => {
            paket.push({
              idPaket: data.idPaketOptions,
              nama: data.nama,
            });
          });
          this.paket = paket;
          // console.table(this.paket);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    next() {
      if (this.$refs.form.validate() === false) {
        this.$refs.form.validate();
      } else {
        this.$router.push({ name: "Paket", params: { data: this.form } });
      }
    },
  },
};
</script>