<template>
  <paket></paket>
</template>

<script>
import paket from '../components/Paket/paket.vue';
export default {
  components: { paket },
  name: "Paket",
};
</script>

<style>
</style>