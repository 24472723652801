<script>
import axios from "axios";
import jsPDF from "jspdf";
import { paket, paketlayanan, registerDetail } from "../../services/index";
export default {
  data: () => ({
    namaPaket: "",
    loading: false,
    download: false,
    paket: [],
    statusBayar: {
      return: "",
      trx_id: "",
      status: "",
      tipe: "",
      via: "",
      channel: "",
      va: "",
      displayName: "",
    },
    dataUser: {
      emailAdd: "",
      harga: "",
      ig: "",
      invoice: "",
      lama: "",
      name: "",
      paketName: "",
      phone: "",
      potongan: "",
      status: "",
      vat: "",
      hargaVat: "",
      hargaPotongan: "",
      totalHarga: "",
      waktuBayar: "",
    },
    form: {
      nama: "",
      noWA: "",
      akunIG: "",
      Email: "",
      paket: "",
    },
    rules: [(v) => !!v || "required"],
   
  }),

  mounted() {
    this.statusBayar = this.$route.query;
    if (this.statusBayar.trx_id != undefined) {
      this.loadData(this.statusBayar.trx_id);
    }
  },

  methods: {
    loadData(id) {
      try {
        axios.get(registerDetail + "/" + id).then((response) => {
          switch (response.data.status) {
            case 200:
              console.log( response.data.results);
              this.dataUser = response.data.results;
          
              break;
            default:
              console.log(response.data.message);
              break;
          }
        });
      } catch (error) {
        console.log(err);
      }
    },
  },
};
</script>