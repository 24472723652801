let baseUrl = "https://kalenderkonten.com/app-dashboard";
let register = `${baseUrl}/registerpaket/simpan`;
let registerDetail = baseUrl + "/detailregister";
// let createregister = `${baseUrl}/registerpaket`;
let paket = `${baseUrl}/paket`;
let opsi = `${baseUrl}/opsibayar`;
const kuponByCode = `${baseUrl}/kuponbycode/`;
const paketlayanan = baseUrl + "/paket/layanan";
// let apiKey          = "F9A7E286-033B-4FB2-B3DB-B3639AA7A0B9"; //sandbox
// let vaKey              = "1179002168422225";//sandbox
let vaKey  = '1179002168422225';
let apiKey ='F9A7E286-033B-4FB2-B3DB-B3639AA7A0B9';
// url api
// var urlDirect             = 'https://sandbox.ipaymu.com/api/v2/payment/direct';
// var urlReDirect           = 'https://sandbox.ipaymu.com/api/v2/payment';
var urlDirect = 'https://my.ipaymu.com/api/v2/payment/direct';
var urlReDirect = 'https://my.ipaymu.com/api/v2/payment';

export {
  register,
  registerDetail,
  paket,
  opsi,
  kuponByCode,
  apiKey,
  urlReDirect,
  urlDirect,
  vaKey,
  // createregister,
  paketlayanan,
};
