<script>
import axios from "axios";
import jsPDF from "jspdf";
import {
  paket,
  opsi,
  kuponByCode,
  register,
  paketlayanan,
  apiKey,
  urlReDirect,
  vaKey,
} from "../../services/index";
// import { ketentuan, kebijakan, ketentuanDanKebijakan } from "../../components/index";
export default {
  // name: "paket",
  // components: {
  //   ketentuanDanKebijakan,
  // },
  data() {
    return {
      gambar: "../../assets/Mandiri.jpeg",
      dialogKupon: false,
      getKupon: null,
      dataKupon: null,
      choicePaket: {
        harga: 0,
        id: "",
        nama: "",
        hargaHemat: 0,
        durasi: 0,
        discount: 0,
        hargaVat: 0,
        vat: 0,
      },
      choiceBayar: "",
      getDiskon: null,
      getVat: null,
      potonganSpesial: null,
      getTotal: null,
      snackbar: false,
      snackbarText: "",
      colorSnackbar: "",
      selectBefore: { harga: 0, id: "", nama: "", durasi: 0 },
      selectedPaket: { harga: 0, id: "", nama: "", durasi: 0 },
      selectedPaketId: "",
      paketlayanan: [],
      pay: null,
      e6: 1,
      paketPilihan: [],
      options: [],
      dialogInvoice: false,
      dataUser: {
        idRegister: "",
        nama: "",
        noWA: "",
        Email: "",
        akunIG: "",
        idPaketOptions: "",
        idOpsiBayar: "",
        paket: "",
        idKupon: "",
        // paket:[]
      },
      dialogKetentuan: false,
      dialogKebijakan: false,
      register: null,
      // ===========================================
      paketlayanan: [],
      loading: false,
    };
  },

  async mounted() {
    this.dataUser = this.$route.params.data;
    await this.loadPaketLayanan();
    this.loadPaket();
  },

  methods: {
    editUser(item) {
      this.dialogEdit = true;
      this.editItem = item;
    },
    loadPaket() {
      this.paketPilihan = [];
      try {
        axios
          .get(paket)
          .then((result) => {
            result.data.results.forEach((data) => {
              let harga = 0;
              harga =
                parseInt(this.selectBefore.harga) *
                parseInt(data.lamaLangganan);
              let potongan = 0;
              potongan = (harga * parseInt(data.discount)) / 100;
              let totHarga = harga - potongan;
              this.paketPilihan.push({
                idPaketOptions: data.idPaketOptions,
                nama: data.nama,
                harga: totHarga,
                hargaHemat: potongan,
                discount: data.discount,
                vat: data.vat,
                hargaVat:
                  parseInt(this.selectBefore.harga) *
                  parseInt(data.lamaLangganan) *
                  (parseInt(data.vat) / 100),
                durasi: parseInt(data.lamaLangganan),
                lamaLangganan: data.lamaLangganan + " bulan",
                description: data.description,
              });
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    },
    loadPaketLayanan() {
      try {
        axios.get(paketlayanan).then((response) => {
          // console.log(response);
          switch (response.data.status) {
            case 200:
              this.paketlayanan = [];
              response.data.results.forEach((data) => {
                this.paketlayanan.push({
                  idPaket: data.id,
                  nama: data.title,
                  harga: data.harga,
                });
              });
              this.selectBefore.id = this.dataUser.paket;
              this.selectedPaket = this.paketlayanan.find(
                (a) => a.idPaket == this.dataUser.paket
              );
              this.selectBefore.nama = this.selectedPaket.nama;
              this.selectBefore.harga = this.selectedPaket.harga;
              break;
            default:
              console.log(response.data.message);
              break;
          }
        });
      } catch (error) {
        console.log(err);
      }
    },

    // ======================================================================================

    loadOptions() {
      axios
        .get(opsi)
        .then((result) => {
          const opsi = [];
          result.data.results.forEach((data) => {
            opsi.push({
              idOpsiBayar: data.idOpsiBayar,
              title: data.title,
              nama: data.nama,
              nomorAkunRekening: data.noAkunRekening,
              img: data.img,
            });
          });
          this.options = opsi;
          // console.table(this.options);
          // // console.table(this.options);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // ======================================================================
    recalculate(value) {
      this.selectBefore = {};
      this.selectBefore.id = value;
      this.selectBefore.harga = this.paketlayanan.find(
        (a) => a.idPaket == value
      ).harga;
      this.loadPaket();
    },
    calculate() {
      if (this.selectedPaketId === "") {
        this.snackbar = true;
        this.snackbarText = "Silahkan pilih paket terlebih dahulu!";
        this.colorSnackbar = "red";
      } else {
        this.choicePaket = this.paketPilihan.find(
          (a) => a.idPaketOptions == this.selectedPaketId
        );
        console.log(this.choicePaket);
        this.e6 = 2;
        this.getTotal = this.choicePaket.hargaVat + this.choicePaket.harga;
      }
    },
    // =======================================================================
    showDialog(value) {
      if (value === true) {
        this.checkValidateKupon();
      } else if (value === false) {
        this.dialogKupon = false;
      }
    },

    checkValidateKupon() {
      try {
        axios
          .get(kuponByCode + `${this.getKupon}`)
          .then((response) => {
            let date = new Date().toISOString().substring(0, 10);
            const validKupon = response.data.results.valid_to.slice(0, 11);

            if (date < validKupon) {
              this.dataKupon = response.data.results;
              this.dialogKupon = false;
              this.getKupon = null;
              // ============================================================================================
              this.snackbar = true;
              this.snackbarText = "Kupon anda berhasil digunakan!";
              this.colorSnackbar = "";
              // ============================================================================================
              let rep = this.choicePaket.harga.replace(".", "");
              let rep1 = rep.replace(".", "");
              let diskon = rep1 * (this.choicePaket.discount / 100);
              let vat = rep1 * (10 / 100);
              this.getDiskon = new Intl.NumberFormat("de-DE").format(diskon);
              this.getVat = new Intl.NumberFormat("de-DE").format(vat);
              this.potonganSpesial = this.getTotal = new Intl.NumberFormat(
                "de-DE"
              ).format(
                (rep1 - diskon + vat) * (this.dataKupon.potongan_spesial / 100)
              );
              this.getTotal = new Intl.NumberFormat("de-DE").format(
                // rep1 - diskon + vat
                rep1 -
                  diskon +
                  vat -
                  (rep1 - diskon + vat) *
                    (this.dataKupon.potongan_spesial / 100)
              );
              console.table(this.dataKupon);
            } else if (date > validKupon) {
              this.dataKupon = null;
              this.dialogKupon = false;
              f;
              this.getKupon = null;
              // ============================================================================================
              this.snackbar = true;
              this.snackbarText = "Maaf kupon anda sudah tidak valid!";
              this.colorSnackbar = "red";
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    },

    createdInvoice() {
      let formData = new FormData();
      formData.append("nama", this.dataUser.nama);
      formData.append("noWA", this.dataUser.noWA);
      formData.append("email", this.dataUser.Email);
      formData.append("akunIG", this.dataUser.akunIG);
      formData.append("idPaketOptions", this.choicePaket.idPaketOptions);
      formData.append("namaPaket", this.selectedPaket.nama);
      formData.append("durasi", this.choicePaket.durasi);
      formData.append("harga", this.choicePaket.harga);
      formData.append("hargaPotongan", this.choicePaket.hargaHemat);
      formData.append("disc", this.choicePaket.discount);
      formData.append("hargaVat", this.choicePaket.hargaVat);
      formData.append("vat", this.choicePaket.vat);
      formData.append(
        "idKupon",
        this.dataKupon == null ? "0" : this.dataKupon.idKupon
      );

      try {
        const config = {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        };

        //  this.paymentIPaymu('1643796778');
        this.loading = true;
        axios
          .post(register, formData)
          .then((response) => {
            this.loading = false;
            //console.log(response.data.results)
            this.paymentIPaymu(response.data.results);
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });

        // this.downloadPdf();
      } catch (error) {
        console.log(error);
      }
    },
    paymentIPaymu(id) {
      this.loading = true;
      let namaPaket = this.selectBefore.nama + " " + this.choicePaket.nama;
      var body = {
        account: vaKey,
        buyerName: this.dataUser.nama,
        buyerEmail: this.dataUser.Email,
        buyerPhone: "+62" + this.dataUser.noWA,
        price: [this.choicePaket.harga],
        description: namaPaket,
        referenceId: id,
        expired: "24",
        expiredType: "hours",
        qty: ["1"],
        description: [namaPaket],
        product: [this.selectedPaket.nama],
        returnUrl: "https://invoice.kalenderkonten.com/payment",
        notifyUrl: "https://kalenderkonten.com/app-dashboard/pemberitahuan",
        cancelUrl: "https://kalenderkonten.com/app-dashboard/batal",
      };
      var bodyEncrypt = this.$CryptoJS.SHA256(JSON.stringify(body));
      var stringtosign = "POST:" + vaKey + ":" + bodyEncrypt + ":" + apiKey;
      var signature = this.$CryptoJS.enc.Hex.stringify(
        this.$CryptoJS.HmacSHA256(stringtosign, apiKey)
      );

      var config = {
        method: "post",
        url: urlReDirect,
        headers: {
          "Content-Type": "application/json",
          signature: signature,
          va: vaKey,
          timestamp: Date.now(),
        },
        data: body,
      };

      axios(config)
        .then((response) => {
          this.loading = false;
          window.open(response.data.Data.Url, "_parent");
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    downloadPdf() {
      let pdf = new jsPDF();
      pdf.text("IMAGE", 10, 10);
      pdf.text("STATUS ORDER", 160, 10);
      pdf.text("INVOICE NO.", 10, 20);
      pdf.text("Dalam Proses", 160, 20);
      pdf.text("INVOICE DATE/TIME.", 10, 30);
      pdf.text("Terakhir Diubah", 100, 30);
      // pdf.text("Dalam Proses", 10, 10);
      pdf.save("invoice.pdf");
    },
  },
};
</script>