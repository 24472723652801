<template>
  <register></register>
</template>

<script>
import { register } from "../components/index";
export default {
  components: { register },
  name: "Register",
};
</script>

<style>
</style>